$theme: ekonomi;
@use "design-system" as ds;

.background {
  @include ds.background(backdrop, $theme: $theme, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
