$theme: ekonomi;
@use "design-system" as ds;

.icon {
  display: inline-block;

  & svg {
    overflow: visible;
    pointer-events: all;
  }
}

.iconColor {
  @include ds.colorise-icon(text, primary, $theme: $theme);
}

.inPrimaryButton {
  @include ds.colorise-icon(text, tertiary, $theme: $theme);
}

$sizes: (
  "small": 12px,
  "normal": 16px,
  "medium": 24px,
  "large": 32px,
  "dynamic": 100%,
);

@each $name, $size in $sizes {
  .#{$name} {
    width: $size;
    height: $size;
    line-height: $size;
  }
}
